<template>
    <div class="ui-alert" :class="`ui-alert--${variant}`">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'UiAlert',
    props: {
        variant: {
            type: String,
            default: 'primary',
            required: false,
        },
    },
};
</script>

<style lang="scss">
.ui-alert {
    background-color: #d3eafe;
    border-radius: 8px;
    display: flex;
    padding: 16px;
    gap: 8px;
    color: #2196F3;

    &--light {
        background-color: $general-white;
    }
}
</style>

