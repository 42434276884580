<template>
    <div v-if="open" class="ui-snackbar" :class="{ 'active': active }">
        <slot>
            <div class="ui-snackbar__content" role="alert">
                <div class="ui-snackbar__message">
                    {{ message }}
                </div>
                <div class="ui-snackbar__actions">
                    <slot name="action"></slot>
                    <button class="ui-snackbar__close-button">
                        <span class="fas fa-times"></span>
                    </button>
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'UiSnackbar',
    props: {
        autoHideDuration: {
            type: Number,
            default: null,
            required: false,
        },
        message: {
            type: String,
            default: null,
            required: false,
        },
        open: {
            type: Boolean,
            default: false,
            required: false,
        },
        variant: {
            type: String,
            default: 'info',
            required: false,
        },
    },
    data() {
        return {
            active: false,
        };
    },
    watch: {
        open(value) {
            if (value) {
                setTimeout(() => {
                    this.active = true;
                }, 50);

                if (this.autoHideDuration) {
                    setTimeout(() => {
                        this.active = false;
                    }, this.autoHideDuration - 500);

                    setTimeout(() => {
                        this.$emit('close');
                    }, this.autoHideDuration);
                }
            } else {
                this.active = false;
            }
        },
    },
};
</script>

<style lang="scss">
.ui-snackbar {
    align-items: center;
    border-radius: 8px;
    bottom: 24px;
    box-shadow: 0 4px 8px rgba(#000000, 0.16);
    display: flex;
    justify-content: flex-end;
    left: auto;
    margin-left: 18px;
    position: fixed;
    right: 24px;
    transform: translateX(calc(100% + 30px));
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.25, 1.35);
    z-index: 1400;

    &.active {
        transform: translateX(0);
    }

    &__content {
        align-items: center;
        background-color: $general-black;
        border-radius: 8px;
        color: $white;
        display: flex;
        flex-grow: initial;
        flex-wrap: wrap;
        min-width: 288px;
        padding: 16px;
    }

    &__actions {
        align-items: center;
        display: flex;
        margin-left: auto;
    }

    &__close-button {
        background-color: transparent;
        border: none;
        color: #C4C4C4;
    }
}
</style>
