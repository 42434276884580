export default {
    downloadBlobFile(response) {
        const el = document.createElement('a');
        const match_file_name = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(response.headers['content-disposition']);

        el.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        el.download = match_file_name?.[1] ? match_file_name[1].replace(/['"]/g, '') : 'file.csv';
        document.body.appendChild(el);
        el.click();

        document.body.removeChild(el);
        window.URL.revokeObjectURL(el);
    },
};
