<template>
    <svg
        class="ui-circular-progress"
        :width="size"
        :height="size"
        :viewBox="`-${size * 0.125} -${size * 0.125} ${size * 1.25} ${size * 1.25}`"
    >
        <circle
            class="ui-circular-progress__circle"
            :r="(size / 2)"
            :cx="size / 2"
            :cy="size / 2"
            fill="transparent"
            :stroke-width="circleWidth"
            :stroke-dasharray="circumference"
            stroke-dashoffset="0"
        />
        <circle
            class="ui-circular-progress__progress"
            :r="(size / 2)"
            :cx="size / 2"
            :cy="size / 2"
            fill="transparent"
            :stroke-width="progressWidth"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="percentage"
        />
    </svg>
</template>

<script>
export default {
    name: 'UiLinearProgress',
    props: {
        circleWidth: {
            type: Number,
            default: 4,
            required: false,
        },
        max: {
            type: Number,
            default: 100,
            required: false,
        },
        progress: {
            type: Number,
            default: 0,
            required: false,
        },
        progressWidth: {
            type: Number,
            default: 4,
            required: false,
        },
        size: {
            type: Number,
            default: 24,
            required: false,
        },
    },
    data() {
        return {
            radius: 20,
        }
    },
    computed: {
        circumference() {
            const radius = (this.size / 2);
            // 2 × π × radius
            return 2 * Math.PI * radius;
        },
        percentage() {
            // circumference × ((100 - progress)/100)
            return this.circumference * ((this.max - this.progress) / this.max);
        },
    },
};
</script>

<style lang="scss">
.ui-circular-progress {
    transform: rotate(-90deg);

    &__circle {
        stroke: $ecart-secondary-100;
    }

    &__progress {
        stroke: $ecart-primary;
        transition: stroke-dashoffset 300ms linear;
    }
}
</style>
