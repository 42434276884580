<template>
    <div class="download-report-snackbar">
        <ui-snackbar :open="open" message="This is a toast message" @close="downloadingReport = false">
            <ui-alert class="download-report-snackbar__alert" variant="light">
                <div class="w-100">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div class="d-flex">
                            <h6 class="download-report-snackbar__title">
                                <template v-if="totalActiveDownloads">
                                    Downloading {{ totalActiveDownloads }} report{{ downloads.length > 1 ? 's' : '' }}
                                </template>
                                <template v-else>
                                    {{ downloads.length }} report{{ downloads.length > 1 ? 's' : '' }} downloaded
                                </template>
                            </h6>
                        </div>
                        <button class="download-report-snackbar__close-button" @click="close">
                            <span class="fas fa-times"></span>
                        </button>
                    </div>
                    <p class="download-report-snackbar__text mb-2">
                        <template v-if="totalActiveDownloads">
                            Your report{{ downloads.length > 1 ? 's' : '' }} is being created, please wait.
                        </template>
                        <template v-else>
                            Your report{{ downloads.length > 1 ? 's' : '' }} {{ downloads.length > 1 ? 'where' : 'was' }} generated successfully.
                        </template>
                    </p>
                    <div class="download-report-snackbar__list">
                        <div v-for="download in downloads" :key="download.name" class="download-report-snackbar__list-item">
                            <div class="download-report-snackbar__list-item-content">
                                <span class="download-report-snackbar__file-icon fas fa-file-csv"></span>
                                <p class="download-report-snackbar__file-name">{{ download.name }}</p>
                            </div>
                            <div v-if="download.progress.current >= 100" class="download-report-snackbar__success-icon">
                                <span class="fas fa-check"></span>
                            </div>
                            <ui-circular-progress v-else :progress="download.progress.current" :max="download.progress.total" :size="20" />
                        </div>
                    </div>
                </div>
            </ui-alert>
        </ui-snackbar>
    </div>
</template>

<script>
import UiAlert from '@/components/ui/alert/Alert.vue';
import UiCircularProgress from '@/components/ui/progress/CircularProgress.vue';
import UiSnackbar from '@/components/ui/snackbar/Snackbar';

import downloadUtils from '@/utils/downloads.utils';

export default {
    name: 'DownloadReportSnackbar',
    components: {
        UiAlert,
        UiCircularProgress,
        UiSnackbar,
    },
    data() {
        return {
            downloads: [],
            open: false,
        };
    },
    computed: {
        totalActiveDownloads() {
            return this.downloads.filter((download) => download.progress.current < 100).length;
        },
    },
    methods: {
        close() {
            this.open = false;
            this.downloads.forEach((download) => {
                if (download.progress.current < 100) {
                    download.controller.abort();
                }
            });
            this.downloads = [];
        },
        cancel() {
            this.controller.abort()
        },
        async download(report) {
            try {
                this.open = true;
                this.downloads.push(report);

                const index = this.downloads.length - 1;

                this.downloads[index].progress = { current: 0, total: 100 };
                this.downloads[index].controller = new AbortController();

                const vm = this;
                const config = {
                    signal: this.downloads[index].controller.signal,
                    responseType: 'blob',
                    onDownloadProgress: function() {
                        const { current } = vm.downloads[index].progress;

                        if (current < 60) {
                            vm.downloads[index].progress.current = current + 10;
                        }

                        if (current >= 60 && current < 80) {
                            vm.downloads[index].progress.current = current + 5;
                        }

                        if (current >= 80 && current < 90) {
                            vm.downloads[index].progress.current = current + 2;
                        }

                        if (current >= 90 && current < 98) {
                            vm.downloads[index].progress.current = current + 1;
                        }
                    },
                };
                const response = await this.$axios.get(report.url, config);

                vm.downloads[index].progress.current = vm.downloads[index].progress.total;
                downloadUtils.downloadBlobFile(response);
            } catch (error) {
                if (error?.message !== 'canceled') {
                    this.$toast.error(this.getErrorMessage(error));
                }
            } finally {
                this.$emit('downloadFinished');
            }
        },
    },
};
</script>

<style lang="scss">
.download-report-snackbar {
    &__icon {
        color: $general-black;
        margin-top: 2px;
        margin-right: 4px;
    }

    &__title {
        color: $general-black;
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0;
    }

    &__text {
        color: $ecart-secondary-600;
        margin-bottom: 4px;
    }

    &__close-button {
        background-color: transparent;
        border: none;
        color: $general-black;
    }

    &__cancel-button {
        background-color: transparent;
        border: none;
        color: $general-black;
        font-weight: 600;
        padding: 0;
    }

    &__list {
        max-height: 250px;
        overflow-y: auto;
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;
        transition: 300ms ease-in;
    }

    &__list-item-content {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__file-icon {
        color: $ecart-secondary-500;
        font-size: 1.15rem;
    }

    &__file-name {
        color: $ecart-secondary-500;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__success-icon {
        align-items: center;
        background-color: $ecart-primary;
        border-radius: 100%;
        color: $white;
        display: flex;
        font-size: 0.73rem;
        height: 20px;
        justify-content: center;
        width: 20px;
    }
}
</style>
